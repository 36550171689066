import { useUser } from './useUser'

const useHomeLinkAttributes = () => {
    const { session } = useUser()

    return {
        href: session?.isLoggedIn ? '/' : 'https://www.sda.au',
        target: session?.isLoggedIn ? '_self' : '_blank',
    }
}

export default useHomeLinkAttributes
  