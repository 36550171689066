import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import { FC } from 'react'
import styled from 'styled-components'
import SocialLinks from './SocialLinks'
import useHomeLinkAttributes from '@app/lib/hooks/useHomeLinkAttributes'

const FooterTopContainer = styled.div`
  background: ${props => props.theme.header.backgroundColor};
`

const FooterTopSection = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 90rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  @media screen and (min-width: 1040px) {
    padding-top: 0rem;
    padding-bottom: 0rem;
    padding-left: 5.75rem;
    padding-right: 5.75rem;
    height: 11.75rem;
  }
`

const DesktopNav = styled.div`
  display: none;
  @media screen and (min-width: 1040px) {
    display: flex;
    align-items: center;
    > * + * {
      margin-left: 4.5rem;
    }
  }
`

const DesktopNavLink = styled.a`
  font-size: ${props => props.theme.fontSizes.medium};
  font-weight: 700;
  color: ${props => props.theme.colors.white};
  opacity: 0.5;
  &:hover {
    color: ${props => props.theme.colors.info};
    opacity: 1;
  }
`

const ResponsiveNav = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1.375rem;
  @media screen and (min-width: 1040px) {
    display: none;
    padding-bottom: 0;
  }
`

const ResponsiveNavItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: 1px solid #264d70;
  }
`

const ResponsiveNavItemLink = styled.a`
  font-size: ${props => props.theme.fontSizes.medium};
  font-weight: 700;
  color: ${props => props.theme.colors.white};
  opacity: 0.5;
  ${ResponsiveNavItem}:hover & {
    color: ${props => props.theme.colors.info};
    opacity: 1;
  }
`

const ResponsiveNavItemIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  color: ${props => props.theme.colors.info};
`

const Logo = styled.img`
  width: 8.5rem;
  height: 3.25rem;
`

interface INavItem {
  title: string
  url: string
  icon: string
  type: string
}

export interface FooterTopProps {
  logo: string
  navitems: INavItem[]
}

const FooterTop: FC<FooterTopProps> = ({ logo, navitems }) => {
  const homeLinkAttributes = useHomeLinkAttributes()
  
  return (
    <FooterTopContainer>
      <FooterTopSection>
        <a { ...homeLinkAttributes }>
          <Logo src={logo} />
        </a>
        <DesktopNav>
          {navitems?.map((navitem, index) => (
            <Link key={index} href={navitem.url} passHref legacyBehavior>
              <DesktopNavLink target={
                navitem.title &&
                  ['disclaimer', 'privacy policy'].includes(navitem.title.toLowerCase()) &&
                  navitem.url !== '/#' ? '_blank' : '_self'
              }>{navitem.title}</DesktopNavLink>
            </Link>
          ))}
        </DesktopNav>
        <SocialLinks />
      </FooterTopSection>
      <ResponsiveNav>
        {navitems?.map((navitem, index) => (
          <Link key={index} href={navitem.url} passHref legacyBehavior>
            <ResponsiveNavItem>
              <ResponsiveNavItemLink target={
                navitem.title &&
                  ['disclaimer', 'privacy policy'].includes(navitem.title.toLowerCase()) &&
                  navitem.url !== '/#' ? '_blank' : '_self'
              }>{navitem.title}</ResponsiveNavItemLink>
              <ResponsiveNavItemIcon icon={['far', navitem.icon] as IconProp} />
            </ResponsiveNavItem>
          </Link>
        ))}
      </ResponsiveNav>
    </FooterTopContainer>
  )
}

export default FooterTop
